import data from "../data/data"


/***
 * This function gives the maximum percentage of borrowed amount according to age
 * 
 */
export const getMaximumPercentage = (age) => {
    let maximumPercentage = 0;
    if (age >= 85) {
      return 59.28
    }
    data.forEach( i => {
      if(i.age === Number(age)){
        maximumPercentage = i.percentage;
      }
    })
    return maximumPercentage
  }

const compoundInterest = (principal,time,rate) => {
    const CI = principal * (Math.pow((1 + rate / 100), time));
    return CI
}

/***
 * This function gives us the data of property Value,borrowed and equity with interest and percentage. 
 */
export const getTableData = (propertyValue, age,borrowedAmount,houseInflation,planInterest) => {
    let data = []
    let propertyValueYear;
    let borrowedAmountYear;
    let equity;
    let propertyValuePercentage = 0;
    let equityPercentage;
    let propertyYearInc;
    let borrowedAmountDec;
    let equityInc;
    let borrowedAmountPercentage;
    for(let i = 5; i <= 25; i=i+5){
        propertyValueYear = compoundInterest(propertyValue, i,houseInflation)
        propertyYearInc = propertyValueYear - propertyValue
        propertyValuePercentage = ((propertyValueYear - propertyValue)/ propertyValue) * 100;
        borrowedAmountYear = compoundInterest(borrowedAmount, i,planInterest)
        borrowedAmountDec = borrowedAmountYear - borrowedAmount
        borrowedAmountPercentage = (borrowedAmountDec/borrowedAmount) *100
        equity = propertyValueYear - borrowedAmountYear
        equityInc = equity - (propertyValue - borrowedAmount)
        equityPercentage = ((equity - (propertyValue - borrowedAmount))/(propertyValue - borrowedAmount)) *100
        data.push({propertyValue: propertyValueYear,propertyValuePercentage:propertyValuePercentage ? propertyValuePercentage : 0,propertyYearInc: propertyYearInc, amountOwed: borrowedAmountYear,borrowedAmountDec: borrowedAmountDec,borrowedAmountPercentage:borrowedAmountPercentage ? borrowedAmountPercentage : 0, equity: equity,equityInc:equityInc, equityPerentage: equityPercentage ? equityPercentage : 0})
    }

    return data
}