import Form from '../components/Form';
import Chart from '../components/Chart';
import Result from '../components/Result';



const MainPage = () => {
  return (
    <section className="rec-equity-calculator">
      <div className="container">
                <h1> Remaining equity calculator </h1>
    					  <div className="row">
    					    <div className="rec-equity-left-col">
    					       <Form/>
    					    </div>
    					    <div className="rec-equity-right-col">
                     			<Chart/>
    					    	 <Result/>
    					    </div>
    					  </div>
    				</div>
        </section>
  );
}

export default MainPage;
