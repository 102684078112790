import MainPage from './pages/MainPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  return (
      <>
        <MainPage/>
      </>
  );
}

export default App;
