const data = [
  { age: 55, percentage: 29.5 },
  { age: 56, percentage: 30.5 },
  { age: 57, percentage: 31.5 },
  { age: 58, percentage: 33.0 },
  { age: 59, percentage: 34.0 },
  { age: 60, percentage: 36.2 },
  { age: 61, percentage: 37.2 },
  { age: 62, percentage: 38.3 },
  { age: 63, percentage: 39.3 },
  { age: 64, percentage: 40.5 },
  { age: 65, percentage: 41.5 },
  { age: 66, percentage: 42.5 },
  { age: 67, percentage: 43.5 },
  { age: 68, percentage: 44.5 },
  { age: 69, percentage: 45.5 },
  { age: 70, percentage: 46.5 },
  { age: 71, percentage: 47.6 },
  { age: 72, percentage: 48.6 },
  { age: 73, percentage: 49.6 },
  { age: 74, percentage: 50.6 },
  { age: 75, percentage: 51.7 },
  { age: 76, percentage: 52.7 },
  { age: 77, percentage: 53.8 },
  { age: 78, percentage: 54.8 },
  { age: 79, percentage: 55.8 },
  { age: 80, percentage: 57.0 },
  { age: 81, percentage: 58.0 },
  { age: 82, percentage: 58.24 },
  { age: 83, percentage: 59.28 },
  { age: 84, percentage: 59.28 },
];
export default data;
