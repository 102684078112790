import { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux'
import { calculationsSelector } from '../slices/calculations';

const Chart = () => {
  const { calculations, loading, hasErrors } = useSelector(calculationsSelector)

  const HomeValue = calculations.tableData.map((item: any) => {
    return item.propertyValue
  })
  const borrowedValue = calculations.tableData.map((item: any) => {
    return item.amountOwed
  })


  let initialSeries = [
    {
      name: 'Property Value',
      data: [0, Number(calculations.propertyValue), ...HomeValue]
    }, {
      name: 'Amount Owed',
      data: [0, Number(calculations.borrowedAmount), ...borrowedValue]
    }];
  let initialOptions = {
    chart: {
      height: 350,
      type: 'area',
      id: 'equityChart',
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        },
      },
    },
    colors: [ '#4BB166','#00154D'],
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'top'
    },
    selection: {
      enabled: false
    },
    grid: {
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        lines: {
          show: true
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return "£" + value;
        }
      },
    },
    stroke: {
      curve: 'straight'
    },
    markers: {
      size: 0,
      strokeColor: "#fff",
      strokeWidth: 3,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 6
      }
    },
    xaxis: {
      categories: [0, '+5 Years', '+10 Years', '+15 Years', '+20 Years', '+25 Years'],
      labels: {
        style: {
          colors: '#000',
        },
        offsetY: -7,
        offsetX: 0,
      },
      lines: {
        show: true,
      },
    },
    fill: {
      type: "solid",
      colors: ['#4BB166', '#00154D']
    },
  };

  const [options, setOptions] = useState<any>(initialOptions);
  const [series, setSeries] = useState<any>(initialSeries);
  const [randomKey, setRandomKey] = useState<any>(Math.random());

  useEffect(() => {

    let data = calculations.hasOwnProperty('tableData') ? calculations.tableData : [];

    let propertyValue: any = [];
    let amountOwed: any = [];

    data.forEach((element: any) => {
      propertyValue.push(element.propertyValue.toFixed());
      amountOwed.push(element.amountOwed.toFixed());
    });
    
    let seriesTemp = [{
      name: 'Property value',
      data: [calculations.propertyValue,...propertyValue]
    },
    {
      name: 'Amount owed',
      data: [calculations.borrowedAmount,...amountOwed]
    }];

    setSeries(seriesTemp);
    setRandomKey(Math.random());

  }, [calculations]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="area" height={350} key={randomKey} />
    </div>
  );
}

export default Chart;
