import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProperty, setPropertyMax } from "../slices/calculations";
import { calculationsSelector } from "../slices/calculations";
import NumberFormat from "react-number-format";

import { getMaximumPercentage } from "../utils/helpers";

const Form = () => {
  const { calculations, loading, hasErrors } = useSelector(calculationsSelector);
  const [propertyValue, setPropertyValue] = useState("70000");
  const [age, setAge] = useState("60");
  const [borrowedAmount, setBorrowedAmount] = useState("25340");
  const [houseInflation, setHouseInflation] = useState(4);
  const [planInterest, setPlanInterest] = useState(4.67);
  const [visible, setVisible] = useState(false);

  const getMinimumBorrowAmount = (propertyValue:any) => {
        let minimumBorrowAmount = Number(calculations.propertyValue) * 0.05;
        return minimumBorrowAmount < 10000 ? 10000 : minimumBorrowAmount;
  };

  const getMaximumBorrowAmount = (propertyValue:any, age:any) => {
        let maximumBorrowAmount = Number(calculations.propertyValue) * (getMaximumPercentage(age) / 100);
        return maximumBorrowAmount.toFixed();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setProperty(
        propertyValue,
        age,
        borrowedAmount,
        houseInflation,
        planInterest
      )
    );
  }, [propertyValue, age, borrowedAmount, houseInflation, planInterest]);
  return (
    <form className="rec-equity-form">
      <div className="form-row value-row">
        <label> Property value </label>
        <div className="input-group">
          <span className="input-group-addon">£</span>
          <input
            type="number"
            onChange={(e) => setPropertyValue(e.target.value)}
            value={propertyValue}
            min="70000"
            step="1000"
            data-number-to-fixed="2"
            data-number-stepfactor="100"
            className="form-control currency"
            id="c2"
          />
        </div>

        {!(Number(propertyValue) >= 70000) && <p className="rec-error text-danger">The minimum property value needs to be £70,000</p>}
      </div>

      <div className="form-row">
        <label>Client Age</label>
        <input
          type="number"
          onChange={(e) => setAge(e.target.value)}
          value={age}
          min="55"
          max="84"
          className="currency"
          id="c1"
        />
        {!(Number(age) > 54) && <p className="rec-error text-danger">Please enter the age above 54.</p>}
      </div>

      <div className="form-row value-row borrow-row">
        <label> Borrow amount </label>
        <div className="input-group">
          <span className="input-group-addon">£</span>
          <input
            type="number"
            value={calculations.borrowedAmount}
            onChange={(e) => setBorrowedAmount(e.target.value)}
            min="10000"
            max={Number(getMaximumBorrowAmount(calculations.propertyValue, age))}
            step="1000"
            data-number-to-fixed="2"
            data-number-stepfactor="100"
            className="form-control currency"
            id="c2"
          />


          {(Number(borrowedAmount) <
            Number(getMinimumBorrowAmount(calculations.propertyValue)))  && (

            <p className="rec-error text-danger">
              Minimum amount you can borrow is{" "}
              <NumberFormat
                value={getMinimumBorrowAmount(calculations.propertyValue)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"£"}
                decimalScale={0}
              />
              . Please enter an amount above this.
            </p>
          )}

          {(Number(borrowedAmount) >=
            Number(getMaximumBorrowAmount(calculations.propertyValue, age)) + 1) && (Number(age) > 54) && (

            <p className="rec-error text-danger">
              The average amount you can borrow is{" "}
              <NumberFormat
                value={getMaximumBorrowAmount(calculations.propertyValue, age)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"£"}
                decimalScale={0}
              />
              . Please enter a figure below this amount.
            </p>
          )}


          <div className="input-group-prepend">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={(e) => {
                  dispatch(setPropertyMax(propertyValue, age, houseInflation, planInterest))
              }}
             >
              Maximum
            </button>
          </div>
        </div>
        <p>
          {" "}
          The maximum is based on the average LTV available for the customer’s
          age and their property value. Actual maximum available may differ.{" "}
        </p>
      </div>

      <div className="form-row value-row price-inflation-row">
        <label> House price inflation </label>
        <div className="input-group">
          <button
            className="value-button"
            id="decrease"
            onClick={(e) => {
              e.preventDefault();
              if (houseInflation >= 1.5) {
                setHouseInflation(houseInflation - 0.5);
              }
              if (houseInflation >= 1 && houseInflation < 1.5) {
                setHouseInflation(1);
              }
            }}
          >
            -0.5%
          </button>
          <input
            type="number"
            id="number"
            min="1"
            step="0.5"
            max="10"
            value={houseInflation}
            onChange={(e) => setHouseInflation(Number(e.target.value))}
          />
          <button
            className="value-button"
            id="increase"
            onClick={(e) => {
              e.preventDefault();
              if (houseInflation <= 9.5) {
                setHouseInflation(houseInflation + 0.5);
              }
              if (houseInflation > 9.5 && houseInflation <= 10) {
                setHouseInflation(10);
              }
            }}
          >
            +0.5%
          </button>
        </div>
      </div>

      <div className="form-row value-row price-inflation-row">
        <label> Plan Interest Rate </label>
        <div className="input-group">
          <button
            className="value-button"
            id="decrease"
            onClick={(e) => {
              e.preventDefault();
              if (planInterest >= 3.24) {

                setPlanInterest(Number((planInterest - 0.5).toFixed(3)));
              }
              if (planInterest < 3.24 && planInterest >= 2.74) {
                setPlanInterest(2.74);
              }
            }}
          >
            -0.5%
          </button>
          <input
            type="number"
            id="number"
            min="2.74"
            max="6.9"
            value={planInterest}
            onChange={(e) => setPlanInterest(Number(e.target.value))}
          />
          <button
            className="value-button"
            id="increase"
            onClick={(e) => {
              e.preventDefault();
              if (planInterest <= 6.4) {
                setPlanInterest(Number((planInterest + 0.5).toFixed(3)));
              }
              if (planInterest <= 6.9 && planInterest > 6.4) {
                setPlanInterest(6.9);
              }
            }}
          >
            +0.5%
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
