import { useSelector } from 'react-redux'
import { calculationsSelector } from '../slices/calculations';
import NumberFormat from 'react-number-format'
const Result = () => {
  const { calculations, loading, hasErrors } = useSelector(calculationsSelector)
  return (
    <>
    <p className="rec-years">Years</p>
    <div className="rec-tableSec">
        <table className="table table-bordered rec-resultTable">
          <thead>
            <tr>
              <th></th>
              <th>Property value</th>
              <th>Amount Owed</th>
              <th>Equity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Today</td>
              <td><NumberFormat value={calculations.propertyValue} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /></td>
              <td><NumberFormat value={calculations.borrowedAmount} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /></td>
              <td><NumberFormat value={Number(calculations.propertyValue) - Number(calculations.borrowedAmount)} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /></td>
            </tr>

            {calculations.tableData.map((item: any, index: any) => {

              return <tr key={index}>
                <td>+ {(index + 1) * 5} Years</td>
                <td><NumberFormat value={item.propertyValue} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /> <span className="price_inc">+<NumberFormat value={item.propertyYearInc} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /> ({(item.propertyValuePercentage).toFixed(1)}%)</span></td>
                <td><NumberFormat value={item.amountOwed} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /> <span className="price_dec">+<NumberFormat value={item.borrowedAmountDec} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /> ({item.borrowedAmountPercentage.toFixed(1)}%)</span></td>
                <td><NumberFormat value={item.equity} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /> <span className="price_inc">+<NumberFormat value={item.equityInc} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={0} /> ({(item.equityPerentage).toFixed(1)}%)</span></td>

              </tr>
            })}

          </tbody>
        </table>
        </div>
    </>
  );
}

export default Result;
