import { createSlice } from '@reduxjs/toolkit'
import {getMaximumPercentage, getTableData} from "../utils/helpers"

export const initialState = {
  loading: false,
  hasErrors: false,
  calculations: {
    propertyValue: "250000",
    age:"55",
    borrowedAmount: "0",
    houseInflation: 2.5,
    planInterest:2.5,
    tableData: []
  },
}

// A slice for posts with our three reducers
const calculationSlice = createSlice({
  name: 'calculations',
  initialState,
  reducers: {
    getPropertyValue: (state) => {
      state.loading= true
    },
    getPropertyValueSuccess: (state, {payload}) => {
      state.calculations = payload
      state.loading = false
      state.hasErrors = false
    },
    getPropertyValueFailure: (state) => {
      state.loading = false
      state.hasErrors = true
    },
  },
})

// Three actions generated from the slice
export const { getPropertyValue, getPropertyValueSuccess, getPropertyValueFailure } = calculationSlice.actions

// A selector
export const calculationsSelector = (state) => state.calculations

// The reducer
export default calculationSlice.reducer


export function setProperty(propertyValue, age,borrowedAmount,houseInflation,planInterest){
  return async (dispatch) => {
    dispatch(getPropertyValue())
    try {
      const tableData = getTableData(propertyValue, age,borrowedAmount,houseInflation,planInterest)
      const data = {propertyValue: propertyValue ? propertyValue : 0, age: age ? age : 0,borrowedAmount: borrowedAmount,houseInflation,planInterest,tableData}

      dispatch(getPropertyValueSuccess(data))
    } catch(err){
      console.log(err)
      dispatch(getPropertyValueFailure())
    }
  }
}

export function setPropertyMax(propertyValue, age,houseInflation,planInterest) {
  return async (dispatch) => {
    dispatch(getPropertyValue())
    try {
      let borrowedAmount
      if(Number(age) > 84){
         borrowedAmount = (Number(propertyValue) * 0.5928)

      } else {
       borrowedAmount = (Number(propertyValue) * (getMaximumPercentage(age)/100))
      }
      const tableData = getTableData(propertyValue, age,borrowedAmount,houseInflation,planInterest)
      const data = {propertyValue, age,borrowedAmount: borrowedAmount.toFixed(2), houseInflation,planInterest,tableData}
      dispatch(getPropertyValueSuccess(data))
    } catch(err){
      console.log(err)
      dispatch(getPropertyValueFailure())
    }
  }
}

// // Asynchronous thunk action
// export function fetchPosts() {
//   return async (dispatch) => {
//     dispatch(getPosts())

//     try {
//       const response = await fetch('https://jsonplaceholder.typicode.com/posts')
//       const data = await response.json()

//       dispatch(getPostsSuccess(data))
//     } catch (error) {
//       dispatch(getPostsFailure())
//     }
//   }
// }
